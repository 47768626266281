import { MathHelper } from "@utils/math-helper.js";

export class StringHelper {

	static getNewGuid() {
		return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
			let r = Math.random() * 16 | 0, v = c === "x" ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	}

	static formatAmount(value, showCurrencySymbol) {
		let amount = MathHelper.roundNumber(value, 2);
		return StringHelper.formatAmountExtended(amount, "nl-NL", "EUR", showCurrencySymbol);
	}

	static formatAmountExtended(number, locale, currency, showCurrencySymbol) {
		let parts = new Intl.NumberFormat(locale, {
			style: "currency",
			currency
		})
			.formatToParts(number);

		if (!showCurrencySymbol) {
			parts = parts.filter(x => x.type !== "currency");
		}

		return parts
			.map(x => x.value)
			.join("")
			.trim();
	}

	static isNullOrEmpty(val) {
		return val === undefined || val === null || val === "";
	};

	static isNullOrWhitespace(val) {
		return val === undefined || val === null || val === "" || val === " ";
	};

	static toMultiLine(val) {
		return this.isNullOrEmpty(val) ? "" : val.replace(/\r\n|\r|\n/g, "<br>");
	};

	static containsNumber(value) {
		return /\d/.test(value);
	};

	static toProperCase(value) {
		return value.replace(/\w\S*/g, x => x.charAt(0).toUpperCase() + x.substr(1));
	};

	static contains(str1, str2) {
		return str1.toLowerCase().indexOf(str2.toLowerCase()) >= 0;
	}

	static insert(str, index, extra) {
		return str.slice(0, index) + extra + str.slice(index);
	}

	static left(str, length) {

		if (str === null || str === undefined || str.length === 0) {
			return "";
		}

		return str.slice(0, length);
	}

	static replaceEscapedAmpersand(str) {
		return str.replaceAll("&amp;", "&");
	}

	static trim(str) {
		if (StringHelper.isNullOrEmpty(str)) {
			return str;
		}

		return str.trim();
	}
}